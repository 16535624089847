.cus-container-footer {
  max-width: 1120px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.brandingDesktop {
  max-width: 1120px !important;
}
.cus-btn-footer {
  background-color: black !important;
  border-color: black !important;
  cursor: auto !important;
  width: 100%;
}

.justify-space-between {
  justify-content: space-between;
}

/* .cus-col {
  max-width: 33.333333%;
  margin-left: 40px;
} */

/* .cus-col-socio {
  max-width: 33.333333%;
} */

.img-width {
  width: 24%;
}

.ml-100 {
  margin-left: 14px;
  background-color: white;
}

.ptr-10 {
  padding-right: 10px;
}

.position-gplus {
  position: relative;
  left: 20px;
}

.position-youtube {
  position: relative;
  left: 40px;
}
/* .cus-col-ph {
  max-width: 33.333333%;
  margin-left: 75px;
} */

.copyryt {
  text-align: center;
  padding-top: 40px;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
  font-size: 20px;
  padding-bottom: 20px;
}

.line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1%;
  background-image: linear-gradient(to right, transparent 50%, #36b9ff 50%);
  background-size: 20px 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.posrel {
  margin-top: 70px;
  position: relative;
}

.cus-col {
  z-index: 1000;
  background-color: transparent;
}

.ph a {
  color: white;
  text-decoration: none;
}

.beak {
  color: black !important;
  text-decoration: none !important;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}
