.select-div {
  text-align: left;
}

.select-text {
  color: #3950a1;
  font-family: "Proxima Nova Lt";
  font-size: 62px;
  font-weight: 300;
  line-height: normal;
}

/* .cus-container {
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-left: 46px !important;
} */

.tiger-img {
  width: 100%;
}

.pt-10 {
  padding: 10px;
}
.pt-40 {
  padding-top: 40px;
}
.shadow {
  box-shadow: 0px 0px 30px #ade1ff !important;
}
.mrbottomextended {
  margin-bottom: 20%;
}
.cus-card-body {
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-align: center !important;
  padding-top: 0px !important;
  padding-bottom: 20px !important;
}

.cus-btn {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 120px !important;
  padding-right: 120px !important;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
  font-size: 17px;
  box-shadow: 0px 0px 5px #003f72;
}

.cus-btn-primary {
  background-color: #0a3558 !important;
  border-color: #0a3558 !important;
}
.displayinmobile {
  display: none;
}

.diableindesktop {
  display: none;
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
  .welcome {
    font-size: 43px;
  }
  .name {
    font-size: 42px;
  }
  .work {
    font-size: 32px;
  }
  .cus-btn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 80px !important;
    padding-right: 80px !important;
    font-family: "Proxima Nova Rg";
    font-weight: normal;
    font-size: 17px;
    box-shadow: 0px 0px 5px #003f72;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .welcome {
    font-size: 35px;
  }
  .name {
    font-size: 30px;
  }
  .work {
    font-size: 23px;
  }
  .cus-btn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 40% !important;
    padding-right: 40% !important;
    font-family: "Proxima Nova Rg";
    font-weight: normal;
    font-size: 25px;
    box-shadow: 0px 0px 5px #003f72;
  }
  .disableinmobile {
    display: none;
  }
  .cusCol12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }
  .cus-card-body p {
    font-size: 30px;
  }
  .posrel {
    display: none;
  }
  .displayinmobile {
    display: block;
  }
  .brandingDesktop {
    display: none;
  }
  .img-width {
    width: 10% !important;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .middle {
    text-align: center;
    margin-bottom: 40px;
  }
  /* .btn {
    padding: 0.375rem 4.75rem;
  } */
  .position-gplus {
    left: 0px !important;
  }
  .position-youtube {
    left: 0px !important;
  }
  .copyryt {
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 420px) {
  .dissableinmobile {
    display: none;
  }
  .herodisable {
    display: none;
  }
  .logo-img {
    padding-top: 10px;
    width: 50%;
  }
  .diableindesktop {
    display: block;
  }
  .BurgerMenu{    
    float: right;
    margin-top: 25px;
  }
  .BurgerMenu span{
    margin-right: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #3950a1;
  }
  .cuscolmd4 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .cuscolmd8 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .disableinmobile {
    display: none;
  }
  .cusCol12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }
  .displayinmobile {
    display: block;
  }
  /* FOOTER */
  .posrel {
    display: none;
  }
  .brandingDesktop {
    display: none;
  }
  .img-width {
    width: 15% !important;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .middle {
    text-align: center;
    margin-bottom: 40px;
  }
  /* .btn {
    padding: 0.375rem 3.75rem;
  } */
  .position-gplus {
    left: 0px !important;
  }
  .position-youtube {
    left: 0px !important;
  }
  .copyryt {
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 419px) and (min-width: 360px) {
  .dissableinmobile {
    display: none;
  }
  .herodisable {
    display: none;
  }
  .logo-img {
    padding-top: 10px;
    width: 100%;
  }
  .diableindesktop {
    display: block;
  }
  .cuscolmd4 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .cuscolmd8 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .disableinmobile {
    display: none;
  }
  .BurgerMenu{    
    float: right;
    margin-top: 25px;
  }
  .BurgerMenu span{
    margin-right: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #3950a1;
  }
  .cusCol12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }
  .displayinmobile {
    display: block;
  }
  .cus-btn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 30% !important;
    padding-right: 30% !important;
    font-family: "Proxima Nova Rg";
    font-weight: normal;
    font-size: 25px;
    box-shadow: 0px 0px 5px #003f72;
  }
  /* FOOTER */
  .posrel {
    display: none;
  }
  .brandingDesktop {
    display: none;
  }
  .img-width {
    width: 18% !important;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 18px;
    margin-right: 18px;
  }
  .middle {
    text-align: center;
    margin-bottom: 40px;
  }
  /* .btn {
    padding: 0.375rem 1.75rem;
  } */
  .position-gplus {
    left: 0px !important;
  }
  .position-youtube {
    left: 0px !important;
  }
  .copyryt {
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .select-text {
    font-size: 46px;
}
  .dissableinmobile {
    display: none;
  }
  .herodisable {
    display: none;
  }
  .logo-img {
    padding-top: 10px;
    width: 100%;
  }
  .diableindesktop {
    display: block;
  }
  .cuscolmd4 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .BurgerMenu{    
    float: right;
    margin-top: 25px;
  }
  .BurgerMenu span{
    margin-right: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #3950a1;
  }
  .cuscolmd8 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .disableinmobile {
    display: none;
  }
  .cusCol12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }
  .displayinmobile {
    display: block;
  }
  .cus-btn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 23% !important;
    padding-right: 23% !important;
    font-family: "Proxima Nova Rg";
    font-weight: normal;
    font-size: 20px;
    box-shadow: 0px 0px 5px #003f72;
  }
  /* FOOTER */
  .posrel {
    display: none;
  }
  .brandingDesktop {
    display: none;
  }
  .img-width {
    width: 18% !important;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 18px;
    margin-right: 18px;
  }
  .middle {
    text-align: center;
    margin-bottom: 40px;
  }
  .btn {
    font-size: 13px;
  }
  .position-gplus {
    left: 0px !important;
  }
  .position-youtube {
    left: 0px !important;
  }
  .copyryt {
    padding-top: 0px !important;
    font-size: 18px;
  }
}