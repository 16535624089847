@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../src/Components/assets/Fonts/ProximaNova-Bold.woff")
      format("woff2"),
    url("../src/Components/assets/Fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Lt";
  src: url("../src/Components/assets/Fonts/ProximaNova-Semibold.woff2")
      format("woff2"),
    url("../src/Components/assets/Fonts/ProximaNova-Semibold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Bl";
  src: url("../src/Components/assets/Fonts/ProximaNova-Black.woff2")
      format("woff2"),
    url("../src/Components/assets/Fonts/ProximaNova-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Th";
  src: url("../src/Components/assets/Fonts/ProximaNova-Extrabld.woff2")
      format("woff2"),
    url("../src/Components/assets/Fonts/ProximaNova-Extrabld.woff")
      format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Th";
  src: url("../src/Components/assets/Fonts/ProximaNovaT-Thin.woff2")
      format("woff2"),
    url("../src/Components/assets/Fonts/ProximaNovaT-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../src/Components/assets/Fonts/ProximaNova-Regular.woff2")
      format("woff2"),
    url("../src/Components/assets/Fonts/ProximaNova-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Lt";
  src: url("../src/Components/assets/Fonts/ProximaNova-Light.woff2")
      format("woff2"),
    url("../src/Components/assets/Fonts/ProximaNova-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

body {
  background-image: linear-gradient(180deg, #d8f1ff 0%, #ffffff 100%);
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
