@font-face {
  font-family: "Airbnb Cereal App Extra";
  src: url("../Packaging/assets/fonts/AirbnbCerealApp-ExtraBold.woff")
      format("woff2"),
    url("../Packaging/assets/fonts/AirbnbCerealApp-ExtraBold.woff")
      format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Lt";
  src: url("../Packaging/assets/fonts/ProximaNova-Light.woff2") format("woff2"),
    url("../Packaging/assets/fonts/ProximaNova-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal App";
  src: url("../Packaging/assets/fonts/AirbnbCerealApp-Black.woff2")
      format("woff2"),
    url("../Packaging/assets/fonts/AirbnbCerealApp-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Bl";
  src: url("../Packaging/assets/fonts/ProximaNova-Black.woff2") format("woff2"),
    url("../Packaging/assets/fonts/ProximaNova-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Lt";
  src: url("../Packaging/assets/fonts/ProximaNova-Semibold.woff2")
      format("woff2"),
    url("../Packaging/assets/fonts/ProximaNova-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal App";
  src: url("../Packaging/assets/fonts/AirbnbCerealApp-Bold.woff2")
      format("woff2"),
    url("../Packaging/assets/fonts/AirbnbCerealApp-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../Packaging/assets/fonts/ProximaNova-Bold.woff2") format("woff2"),
    url("../Packaging/assets/fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal App Book";
  src: url("../Packaging/assets/fonts/AirbnbCerealApp-Book.woff2")
      format("woff2"),
    url("../Packaging/assets/fonts/AirbnbCerealApp-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Th";
  src: url("../Packaging/assets/fonts/ProximaNovaT-Thin.woff2") format("woff2"),
    url("../Packaging/assets/fonts/ProximaNovaT-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../Packaging/assets/fonts/ProximaNova-Regular.woff2")
      format("woff2"),
    url("../Packaging/assets/fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal App";
  src: url("../Packaging/assets/fonts/AirbnbCerealApp-Light.woff2")
      format("woff2"),
    url("../Packaging/assets/fonts/AirbnbCerealApp-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Airbnb Cereal App";
  src: url("../Packaging/assets/fonts/AirbnbCerealApp-Medium.woff2")
      format("woff2"),
    url("../Packaging/assets/fonts/AirbnbCerealApp-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova Th";
  src: url("../Packaging/assets/fonts/ProximaNova-Extrabld.woff2")
      format("woff2"),
    url("../Packaging/assets/fonts/ProximaNova-Extrabld.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

.backButton {
  text-align: center;
  margin-bottom: 20px;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}

.backArrow {
  width: 25%;
  margin-right: 9px;
}

a:not([href]) {
  color: white !important;
}

.actualImg {
  position: absolute;
  top: 94%;
  left: 75%;
  color: white;
  margin: 0;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
}

.__pack-hero-bg {
  background-image: url("./assets/Images/heromain.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 25px;
}

.logo-img-pack {
  width: 70%;
}
.__mt-top {
  margin-top: 25px;
  max-width: 1290px;
}
.__color {
  background-color: white;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.test {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 99999;
  /* width: 100vw; */
  max-width: 100%;
  /* transition: 300ms; */
}
.test .logo-img-pack {
  max-height: 100px;
  width: auto;
}
.test .__li {
  padding-top: 38px;
}

.test .stickImg {
  padding-left: 40px;
}

.test .stickNav {
  padding-right: 40px;
}
.__color2 {
  padding: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px -1px 0 rgba(0, 0, 0, 0.19);
  /* transition: 300ms; */
}
._nav {
}

.__ul {
  padding-left: 0px;
  float: right;
}
.__li {
  list-style: none;
  display: inline-block;
  padding-top: 38px;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 18px;
  color: #b8b8b8;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}

.__pt-195 {
  padding-top: 187px;
}

.__li a {
  color: #b8b8b8;
}

.nav-link {
  padding: 0px !important;
}
.__li a:hover {
  text-decoration: none;
}

.__downloadButton {
  width: 19%;
  margin-left: -7px;
  margin-right: 7px;
}

.dp {
  position: relative;
}

.__li ul {
  list-style: none;
  text-align: left;
  visibility: hidden;
  display: none;
  padding: 0px;
  position: absolute;
  left: 0;
  border: 1px solid;
  background-color: white;
  border-radius: 5px;
}

.dp:hover .vs {
  visibility: visible;
  display: block;
}

.__dp {
  position: relative;
  top: -1px;
  width: 14px;
  left: 3px;
  transform: rotate(0);
  transition: 1s;
}

.dp:hover img {
  transform: rotate(180deg);
  transition: 1s;
}

.secondryudropdown {
  width: 166px !important;
}

.vs li {
  padding: 12px 20px;
  font-size: 15px;
  font-family: "Proxima Nova Lt";
  font-weight: bold;
  border-bottom: 1px solid;
}

.vs a:last-child {
  border-bottom: 0px solid;
}

.vs a:hover {
  background-color: #42240c;
}
.vs a:hover {
  color: white;
}

.vs a {
  display: block;
  padding: 12px 20px;
  font-size: 15px;
  border-bottom: 1px solid;
  font-family: "Proxima Nova Lt";
  font-weight: bold;
}

.businessAtag {
  padding: 12px 7px !important;
  font-size: 14px !important;
}

.button {
  background-color: #192965; /* Green */
  border: none;
  color: white;
  padding: 15px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 6px;
}
.__forBtn {
  padding-left: 15px;
  padding-right: 0px;
}

.downIcon {
  width: 19%;
  margin-left: -7px;
  margin-right: 7px;
}

.button:hover {
  text-decoration: none;
  color: white;
}

.active {
  color: #42240c !important;
}

.__packSolution {
  position: relative;
  top: 40%;
  /* transform: translateX(75%); */
  text-align: -webkit-right;
}

.__square {
  width: 25%;
  background-color: transprent;
  outline: 5px solid white;
  padding: 15px;
}

.__square span {
  font-family: "Proxima Nova Bl";
  font-weight: 900;
  font-size: 50px;
  color: white;
  line-height: 1;
}

.__square span input {
  font-family: "Proxima Nova Bl";
  font-weight: 900;
  font-size: 50px;
  color: white;
  line-height: 1;
}

.__TextPosition {
  position: relative;
  left: 50;
  left: 65%;
  top: 50px;
}

.__GetStarted {
  background-color: #192965;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 23px;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  position: relative;
  left: 6px;
}

.__GetStarted:hover {
  color: white;
  text-decoration: none;
}

.__crtGetStarted {
  width: 9%;
  margin-left: 13px;
  /* margin-bottom: 5px; */
  transform: rotate(90deg);
}

.dpdown:hover .dropdown-menu {
  display: inline-block;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.dropdown-menu {
  font-family: "Proxima Nova Rg" !important;
  font-weight: normal !important;
}

/* About Section */

.__about-pack-img {
  width: 100%;
}

.__pdTop {
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
}

.__divPosition {
  position: relative;
}

.__aboutText {
  color: transparent;
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: 0px 0px 2px;
  position: absolute;
  left: -20%;
  top: -45px;
}

.__aboutText::before {
  content: "ABOUT US";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: -1;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
}

.__pt65 {
  padding-top: 65px;
}

.__JCPtitle {
  font-size: 35px;
  color: #42240c;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
}

.__AboutPara {
  color: #42240c;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
  font-size: 20px;
}

.__crtIconAbout {
  width: 6%;
  margin-left: 10px;
}

.buttonAbout {
  background-color: #192965; /* Green */
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  margin-top: 14px;
}

.buttonAbout:hover {
  text-decoration: none;
  color: white;
}

/* Products Section */

.__ProductsHead {
  text-align: center;
}

.__ProductsHead::before {
  content: "PRODUCTS";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
  transform: translate(-50%, -50%);
  /* left: 29%;
  bottom: -58px; */
}

.__nav-link {
  border: none !important;
}

.__navProduct {
  background-color: #f1f1f1;
  padding-top: 50px;
  position: relative;
}

.__nav-tab {
  border-bottom: 0px solid #dee2e6 !important;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
}

.__nav-tab a {
  /* padding: 0.5rem 3rem !important; */
  color: #b3b3b3 !important;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  font-size: 20px;
  padding: 0 20px !important;
}

.__nav-tab .active {
  background-color: transparent !important;
  border-color: transparent transparent transparent !important;
  color: #42240c !important;
}

.__nav-tab:hover {
  border-color: transparent transparent transparent !important;
}

.__mtTop40 {
  margin-top: 15px;
}

.__tabImg1 {
  background-image: url("assets/Images/proSlide.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.__tabImg2 {
  background-image: url("assets/Images/palletization/palletback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__tabImg3 {
  background-image: url("assets/Images/lashback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__tabImg4 {
  background-image: url("assets/Images/strapCollBack.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.__PalletsPara h3 {
  color: white;
  font-family: "Proxima Nova Th";
  font-weight: 800;
  font-size: 32px;
}

.__PalletsPara p {
  color: white;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  font-size: 18px;
}

.buttonProduct {
  background-color: #192965; /* Green */
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  /* margin-top: 24px; */
}

.buttonProduct:hover {
  text-decoration: none;
  color: white;
}

.__crtIconProduct {
  width: 14%;
  margin-left: 10px;
  margin-bottom: 3px;
}

.__pdtop40 {
  padding-top: 20px;
  /* padding-bottom: 30px; */
}

.__pdbt40 {
  padding-bottom: 40px;
}

.__s1Img {
  width: 100%;
  /* padding-right: 35px; */
}

.__s1Img2 {
  width: 100%;
  /* padding-right: 35px;
  position: absolute;
  top: 4%; */
}

.__s1Img3 {
  width: 100%;
  /* padding-right: 35px;
  position: absolute;
  top: 9%; */
}

.__s1Img4 {
  width: 100%;
  /* padding-right: 35px;
  position: absolute;
  top: 6%; */
}

.__s1Pos {
  position: relative;
}

/* .__s1Img {
  position: absolute;
  top: 8%;
} */

/* Gallery Section */

.__ptTop {
  padding-top: 60px;
}
.__fluidCon {
  padding-left: 40px;
  padding-right: 40px;
}
.__GalleryPackHead::before {
  content: "GALLERY";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
  top: -36px;
  left: 50px;
}

.__cusCol30 {
  flex: 0 0 30%;
  max-width: 30%;
}

.__cusCol70 {
  flex: 0 0 70%;
  max-width: 70%;
}
.__insideJCP {
  background-color: #f9f9f9;
  padding-top: 20px;
  padding-bottom: 100px;
  position: relative;
}

.__Inside {
  margin-top: 35px;
  color: #42240c;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  font-size: 22px;
  padding-left: 24px;
  position: relative;
}

.__Inside::after {
  content: "";
  width: 80%;
  height: 1px;
  background-color: #42240c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}
.__textEnd {
  text-align: end;
  padding-top: 16px;
  padding-right: 42px;
}

.buttonGallery {
  background-color: #192965;
  border: none;
  color: white;
  padding: 10px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.__arrowimg {
  width: 47%;
}

.__sliderImgs,
.__s1Img {
  width: 100%;
  position: relative;
}

.__sliderImgs:hover {
  filter: blur(4px);
  -webkit-filter: blur(4px);
  border-radius: 20px;
}

.___carosel {
  position: relative;
  bottom: 70px;
}

.__eye {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 20%;
  display: none;
  pointer-events: none;
}

.__vieweye {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 65%;
  color: white;
  display: none;
  pointer-events: none;
}
.__galitem:hover {
  cursor: pointer;
}
.__galitem:hover .__eye {
  display: block !important;
}

.__galitem:hover .__vieweye {
  display: block !important;
}

.__prditem:hover .__st {
  cursor: pointer;
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.__prditem:hover .__eye {
  display: block !important;
}

.__prditem:hover .__prdtitle {
  display: block !important;
}

.__prdtitle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  font-size: 20px;
  font-weight: 500;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;
  display: none;
  pointer-events: none;
}

.__Inside::after {
}

/* Footer  */

.__packFooter::before {
  content: "CONTACT";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 15px;
}
.__CusContainer {
  max-width: 430px;
  text-align: left;
}

.__Connectus {
  color: #42240c;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  font-size: 22px;
  margin: 0px;
}

.__Connectus::after {
  content: "";
  width: 45%;
  height: 1px;
  background-color: #42240c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}

.__center {
  text-align: center;
  background-color: #f9f9f9;
  padding-bottom: 100px;
  padding-top: 40px;
  position: relative;
}
.__Container {
  background-color: white;
}

.__footerSection {
  position: relative;
}

.__mainDiv {
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999;
  top: 250px;
  width: 100%;
}
.__contactMed {
}

.__MediumImg {
  width: 50px;
  margin: 20px;
}

.__clockText span {
  color: #192965;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}

.__MailText a {
  color: #192965;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}

.__MailText a:hover {
  text-decoration: none;
}

.__CallText a {
  color: #192965;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}

.__CallText a:hover {
  text-decoration: none;
}

.__BrandContainer {
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  background-color: #f9f9f9;
  /* position: relative;
  bottom: 70px; */
}

.__ownerSite {
  font-size: 15px;
  padding: 25px;
  color: #192965;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
}

.__siteMaker {
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  color: #192965;
}

.__siteMaker:hover {
  text-decoration: none;
  color: #192965;
}

.__pd20 {
  padding: 20px;
  margin-left: 55px;
}

.__SocialImg {
}

.__lineDivider::after {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #f9f9f9;
  top: 0;
  left: 50%;
  right: 0;
  transform: translateX(142px);
  z-index: 9999;
}

/* Header */

.__pos {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  padding-top: 0px;
}

.__dpForRestProduct {
  position: relative;
  top: -1px;
  width: 14px;
  left: 3px;
  transform: rotate(0);
  transition: 1s;
}

/* About US PAGE */

.__poss {
  position: relative;
}

.__aboutMobile {
  display: none;
}

.__ptBT {
  padding-bottom: 80px;
}
.__aboutPackBG {
  background-image: url("../Packaging/assets/Images/woodabout.png");
  background-size: cover;
  height: 45vh;
  position: relative;
}

.__Head {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  /* bottom: 15px; */
  top: 60%;
}

.__AboutHead {
  font-family: "Proxima Nova Bl";
  font-weight: 900;
  font-size: 65px;
  margin-top: 10px;
  color: white;
  text-shadow: 1px 1px 3px black;
}

.__mainContainer {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 40px;
  max-width: 1350px;
}

.__abImg {
  width: 100%;
  height: auto;
}

._paraDiv {
  margin-top: 50px;
}
.__welcomeJCP {
  font-family: "Proxima Nova Rg";
  font-weight: normal;
  font-size: 20px;
  color: #192965;
}

.__serviceText {
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  font-size: 30px;
  color: #42240c;
}

.__aboutParaDiv {
  margin-top: 20px;
}

.__aboutPara {
  font-family: "Proxima Nova Rg";
  font-weight: normal;
  font-size: 20px;
  color: #42240c;
}

.__achive {
  background-color: #3950a1;
  border: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  position: relative;
  /* transform: translateX(-35%); */
  padding-right: 4%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  margin-top: 20px;
}

.__achive div {
  color: white;
}
.__num {
  font-size: 50px;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  position: relative;
  /* transform: translateX(43%); */
}

.__exp {
  font-family: "Proxima Nova Rg";
  font-weight: normal;
  font-size: 25px;
  position: relative;
  /* transform: translateX(43%); */
}

/* Product Page (STRAPPING TOOLS) */

.__mtbot {
  margin-bottom: 120px;
  margin-top: 25px;
}
.__productBg {
  background-image: url("../Packaging/assets/Images/woodabout.png");
  background-size: cover;
  height: 45vh !important;
  position: relative;
}

.__productName::before {
  content: "STRAPPING";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  /* width: 60%; */
  top: 65px;
}

.__cros {
  background-color: #f1f1f1;
  margin-top: 65px;
}

.__gmaBg {
  background-image: url("../Packaging/assets/Images/pallets/gmaback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__plasticBg {
  background-image: url("../Packaging/assets/Images/pallets/plasticback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__hmBg {
  background-image: url("../Packaging/assets/Images/hmback.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.__ppBg {
  background-image: url("../Packaging/assets/Images/ppback.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.__strapBg {
  background-image: url("../Packaging/assets/Images/strapbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__drumBg {
  background-image: url("../Packaging/assets/Images/palletization/drumback.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.__bagBg {
  background-image: url("../Packaging/assets/Images/palletization/bagback.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.__cartonBg {
  background-image: url("../Packaging/assets/Images/palletization/cartonback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__tesBg {
  background-image: url("../Packaging/assets/Images/tensileback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__wbBg {
  background-image: url("../Packaging/assets/Images/wirebuckleback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__fbBg {
  background-image: url("../Packaging/assets/Images/buckleback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__sbBg {
  background-image: url("../Packaging/assets/Images/steelbuckleback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__sfBg {
  background-image: url("../Packaging/assets/Images/film.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__psBg {
  background-image: url("../Packaging/assets/Images/ps.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__petBg {
  background-image: url("../Packaging/assets/Images/pet.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__cordBg {
  background-image: url("../Packaging/assets/Images/cordstrap.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__rlbBg {
  background-image: url("../Packaging/assets/Images/rlbback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__caBg {
  background-image: url("../Packaging/assets/Images/caback.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__daBg {
  background-image: url("../Packaging/assets/Images/dagBack.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__liftBg {
  background-image: url("../Packaging/assets/Images/lift.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__pcsBg {
  background-image: url("../Packaging/assets/Images/pcs.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__sdBg {
  background-image: url("../Packaging/assets/Images/sd.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__sfdBg {
  background-image: url("../Packaging/assets/Images/sfd.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__mcBg {
  background-image: url("../Packaging/assets/Images/mc.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__boppBg {
  background-image: url("../Packaging/assets/Images/bopp.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__hcBg {
  background-image: url("../Packaging/assets/Images/hsfd.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.__pwBg {
  background-image: url("../Packaging/assets/Images/pw.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__tsBg {
  background-image: url("../Packaging/assets/Images/ts.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__jbBg {
  background-image: url("../Packaging/assets/Images/jbback.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.__productPos {
  max-width: 1350px;
  padding-left: 40px;
  padding-right: 40px;
}

.__b1solid {
  border: 1px solid black;
  border-radius: 15px;
}

.__mTop {
  margin-top: 65px;
  margin-bottom: 10px;
}

.__cusProduct {
  padding-top: 0px !important;
}

.navlink {
  /* padding: 0.5rem 1.5rem !important; */
}

.navtab {
  /* text-align: left;
  margin: inherit; */
}

.navtab a {
  /* padding: 0rem 2rem !important; */
  color: #b3b3b3 !important;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  font-size: 20px;
  padding: 0 20px !important;
}

.__endNext {
  text-align: center;
}

.__endPrev {
  text-align: center;
}

.__prevImg,
.__nextImg {
  width: 30%;
}

.__pad {
  padding: 0;
}

.__padBoth {
  /* padding-left: 40px; */
  padding-right: 40px;
}

.__pad0 {
  padding-left: 0px !important;
}

.sf {
  top: 40px !important;
}

.__st {
  width: 100%;
  position: relative;
  left: 0;
  /* top: 40px; */
}

.__paraArea {
  margin-top: 40px;
}

.__heading {
  font-size: 2.75rem;
  color: white;
  font-family: "Proxima Nova Th";
  font-weight: 800;
  text-shadow: 1px 2px 6px #212121;
}
.__details {
  font-size: 20px;
  color: white;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  text-shadow: 1px 2px 6px #212121;
}

/* .__wireBuckleTable tr {
  border: 1px solid white !important;
} */

.__wireBuckleTable td {
  color: white;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
  font-size: 18px;
  border: none;
}

.__wirebuckleproductdetail {
  color: white;
  font-size: 1.5rem !important;
  font-family: "Proxima Nova Th";
  font-weight: 800;
}

.__serialNumber {
  padding: 10px;
  border: 1px solid #42240c;
  text-align: center;
}

.__serialNumberSP {
}
.__productNameTableSP {
  padding-left: 15px;
  padding-right: 15px;
}

.__productNameTable {
  /* padding-right: 40px; */
  padding-left: 15px;
  border: 1px solid;
}

.dis {
  padding-left: 15px;
}

.__stretchFilmPara {
  font-size: 20px;
  color: white;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}

.__marginForSF {
  margin-bottom: 40px;
}
.__sfTableAfter::after {
  content: "";
  width: 1px;
  height: 220px;
  background-color: white;
  position: absolute;
  top: 0;
  left: -5%;
}

.__marginbottom {
  margin-bottom: 40px;
}
/* PRODUCT PAGE (PALLETS) */

.__productBg {
  background-image: url("../Packaging/assets/Images/woodabout.png");
  background-size: cover;
  height: 60vh;
  position: relative;
}

.__productNamePallet::before {
  content: "PALLET";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 65px;
}

.__cros {
  background-color: #f1f1f1;
  margin-top: 65px;
}

.__productPos {
  max-width: 1350px;
  padding-left: 40px;
  padding-right: 40px;
}

.__mTop {
  margin-top: 65px;
  margin-bottom: 10px;
}

.__cusProduct {
  padding-top: 0px !important;
}

.navlink {
  /* padding: 0.5rem 1.5rem !important; */
}

.navtab {
  /* text-align: left;
  margin: inherit; */
}

.navtab a {
  /* padding: 0rem 2rem !important; */
  color: #b3b3b3 !important;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  font-size: 20px;
}

.__endNext {
  text-align: center;
}

.__endPrev {
  text-align: center;
}

.__prevImg,
.__nextImg {
  width: 30%;
}

.__pad {
  padding: 0;
}

.navigateBtn {
  text-align: center;
  position: relative;
  bottom: 20px;
}

.navigateBtn2 {
  text-align: center;
  margin: 20px 0;
}

/* PRODUCT PAGE (PALLETIZATION) */

.__productBg {
  background-image: url("../Packaging/assets/Images/woodabout.png");
  background-size: cover;
  height: 60vh;
  position: relative;
}

.__productNamePalletization::before {
  content: "PALLETIZATION";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 65px;
}

.__cros {
  background-color: #f1f1f1;
  margin-top: 65px;
}

.__productPos {
  max-width: 1350px;
  padding-left: 40px;
  padding-right: 40px;
}

.__mTop {
  margin-top: 65px;
  margin-bottom: 10px;
}

.__cusProduct {
  padding-top: 0px !important;
}

.navlink {
  /* padding: 0.5rem 1.5rem !important; */
}

.navtab {
  /* text-align: left;
  margin: inherit; */
}

.navtab a {
  /* padding: 0rem 2rem !important; */
  color: #b3b3b3 !important;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  font-size: 20px;
}

.__endNext {
  text-align: center;
}

.__endPrev {
  text-align: center;
}

.__prevImg,
.__nextImg {
  width: 30%;
}

.__pad {
  padding: 0;
}

/* PRODUCT PAGE (LASHING) */

.__productBg {
  background-image: url("../Packaging/assets/Images/woodabout.png");
  background-size: cover;
  height: 60vh;
  position: relative;
}

.__lashBg {
  background-image: url("../Packaging/assets/Images/lash/lb.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.__productNameLashing::before {
  content: "LASHING & CHOCKING";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 65%;
  top: 65px;
  width: 100%;
}

.__cros {
  background-color: #f1f1f1;
  margin-top: 65px;
}

.__productPos {
  max-width: 1350px;
  padding-left: 40px;
  padding-right: 40px;
}

.__mTop {
  margin-top: 65px;
  margin-bottom: 10px;
}

.__cusProduct {
  padding-top: 0px !important;
}

.navlink {
  /* padding: 0.5rem 1.5rem !important; */
}

.navtab {
  /* text-align: left;
  margin: inherit; */
}

.navtab a {
  /* padding: 0rem 2rem !important; */
  color: #b3b3b3 !important;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  font-size: 20px;
}

.__endNext {
  text-align: center;
}

.__endPrev {
  text-align: center;
}

.__prevImg,
.__nextImg {
  width: 30%;
}

.__pad {
  padding: 0;
}

/* PRODUCT PAGE (BOX) */

.__productBg {
  background-image: url("../Packaging/assets/Images/woodabout.png");
  background-size: cover;
  height: 60vh;
  position: relative;
}

.__productNameBox::before {
  content: "BOX & CRATES";
  font-family: "Airbnb Cereal App";
  font-size: 80px;
  font-weight: 900;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #fd5e53;
  text-shadow: -3px 3px 8px #b10b0080;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 65px;
}

.__cros {
  background-color: #f1f1f1;
  margin-top: 65px;
}

.__productPos {
  max-width: 1350px;
  padding-left: 40px;
  padding-right: 40px;
}

.__mTop {
  margin-top: 65px;
  margin-bottom: 10px;
}

.__cusProduct {
  padding-top: 0px !important;
}

.navlink {
  /* padding: 0.5rem 1.5rem !important; */
}

.navtab {
  /* text-align: left;
  margin: inherit; */
}

.navtab a {
  /* padding: 0rem 2rem !important; */
  color: #b3b3b3 !important;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  font-size: 20px;
}

.__endNext {
  text-align: center;
}

.__endPrev {
  text-align: center;
}

.__prevImg,
.__nextImg {
  width: 30%;
}

.__pad {
  padding: 0;
}

/* CONTACT US PACKAGING */

.__contactFormSection {
  margin-top: 50px;
}

.__loveTohearDiv {
}

.__loveTohear {
  color: #42240c;
  font-size: 54px;
  line-height: 1;
  font-family: "Airbnb Cereal App";
  font-weight: 300;
}

.__contactreqDiv {
}

.__contactreq {
  color: #5b5b5b;
  font-size: 25px;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
}

.__companyDiv {
  background-color: #ffffff;
  border: none;
  display: inline-block;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
}

.__conFuild {
  padding: 25px;
}

.__companyImg {
  width: 80%;
}

.__officeAdd {
  color: #42240c;
  font-size: 25px;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}

.__address {
  color: #5b5b5b;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
  font-size: 19px;
}
.__alignRight {
  text-align: right;
}

.__locateUsDiv {
  text-align: center;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 20px;
}

.__locateUs {
  background-color: #192965;
  border: none;
  color: white;
  padding: 1rem 9.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  margin-top: 14px;
  border-radius: 5px;
}

.__locateUs:hover {
  color: white;
  text-decoration: none;
}

.__pin {
  position: relative;
  right: 20px;
  width: 20%;
}

.__contactForm {
  text-align: -webkit-center;
  margin-top: 100px;
}

.__cusFormGroup {
  margin-bottom: 2rem;
}

.__cusFormControl {
  padding: 1.375rem 0.75rem !important;
  width: 85% !important;
  border: 2px solid #a1a1a1 !important;
  box-shadow: 0px 0px 10px #00000029;
}

.__cusFormControl:focus {
  border-color: #dee2e6 !important;
  box-shadow: none !important;
}

textarea.form-control {
  height: 250px !important;
}

.__SubmitButton {
  background-color: #192965;
  border: none;
  color: white;
  padding: 1rem 12.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  margin-top: 14px;
  border-radius: 5px;
}

.__SubmitButton:hover {
  text-decoration: none;
  color: white;
}

.margintop {
  margin-top: 50px;
}

.__cusFormControl::placeholder {
  color: #cccccc;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
}

.__cusFormControl::-webkit-input-placeholder {
  color: #cccccc;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
}

.__mainContactFooter {
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
}

/* PACKAGING GALLERY */

.gujoffice {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.gujoffice h1 {
  font-family: "Proxima Nova Rg";
  font-weight: bold;
  color: #42240c;
}

.__cusContainerFromGallery {
  margin-top: 80px;
  margin-bottom: 80px;
}

.__cusRowInGallery {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}

.__column {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 25%;
  padding: 0 10px;
}

.__galleryImg {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.__card {
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 14px;
}

.__cardBody {
}

.__cardBody img {
  width: 100%;
}

.__cardTitle {
  background-color: white;
  /* display: none; */
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}

/* .__card:hover .__cardTitle {
  display: block !important;
} */

.__cardTitle p {
  padding: 10px;
  color: #42240c;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
}

.__mobileHeader {
  display: none;
}

/* PALLET INNER PAGES */
.__cpcontentSection {
  margin-top: 40px;
  margin-bottom: 70px;
}
.__cpPara {
  font-size: 22px;
  text-align: justify;
}

.__cpTable {
  width: 85%;
  border: 1px solid #42240c;
}
.__cpTable tr {
  color: black;
  font-size: 20px;
  border: 1px solid #42240c;
}

.disableInDesktop {
  display: none;
}
.__tabdisabledesktop {
  display: none;
}
.__footerformobile {
  display: none;
}
.bold {
  font-weight: bold !important;
}
.bgBlack {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 20px;
}

/* JCP FURNITURE */
.logoInFurniture {
  width: 15%;
}
/* .furnitureTopSectionBG {
  background-image: url("../Furniture/assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
} */

.videosection {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#video {
  max-width: 100%;
  min-height: 100%;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgb(33 37 41 / 33%);
}
.cursor {
  cursor: pointer;
}
.contentOver {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
.furniture {
  line-height: 0.9;
  position: absolute;
  top: 68px;
  z-index: 99;
}
.text2020 {
  text-align: center;
}
.text2020:before {
  content: "2020";
  font-family: "Proxima Nova Bl";
  font-size: 125px;
  font-weight: 900;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  z-index: 99;
  -webkit-text-stroke-color: #5b7fff;
  position: relative;
}

.furText {
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  color: white;
  font-size: 65px;
  text-shadow: 0px 2px 50px #212121;
}
.colText {
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  color: white;
  font-size: 95px;
  text-shadow: 0px 2px 50px #212121;
}

.collection {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.productCollection hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.productImg {
  width: 100%;
}
.productDescription h2 {
  color: #354a96;
  font-family: "Proxima Nova Rg";
  font-weight: bold;
}
.productDescription div {
  color: #212121;
  font-family: "Proxima Nova Rg";
  font-weight: normal;
}
.maxWidthForProduct,
.maxWidthForQuality {
  max-width: 885px;
  margin: 0 auto;
}
.iconsFooter {
  text-align: center;
}

.iconsFooter span {
  padding: 0px 20px;
}

.iconsFooter span img {
  width: 10%;
}
.collection h1 {
  font-family: "Proxima Nova Bl";
  font-weight: 900;
  line-height: 0.9;
  margin: 0;
}
.copyRyt {
  font-family: "Proxima Nova Rg";
  font-weight: normal;
}
.marTopsec2 {
  margin: 35px 0;
}
.squareBG {
  position: relative;
  background-color: #ffffff;
  width: 35px;
  height: 55px;
}
.attributes {
  position: absolute;
  top: 0px;
}
.attributes h3 {
  font-family: "Proxima Nova Bl";
  font-weight: 900;
  line-height: 1;
}
.furnitureImg {
  width: 100%;
}
.furnitureImgDiv {
  margin-top: 10px;
}

.lineDiv1::after {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #dedada;
  top: 0;
  right: 35px;
  z-index: 9999;
}
.lineDiv2::after {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #dedada;
  top: 0;
  right: 0;
  transform: translateX(25px);
  z-index: 9999;
}
.disableInDeskTop {
  display: none;
}
.maxWidthForProductMobile {
  display: none;
}
.displayinmobile{
  display: none;
}
.md6 hr{
  display: none;
}
@media screen and (max-width: 1272px) and (min-width: 1201px) {
  /* HEADER */
  .__mt-top {
    padding: 0;
    max-width: 1025px;
  }
  .__pos {
    padding-top: 0px;
  }
  .__li {
    padding-top: 33px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 15px;
  }
  .__forBtn {
    padding-left: 15px;
    padding-right: 0px;
  }
  .headSus {
    padding: 0px !important;
  }
  .button {
    font-size: 14px !important;
  }
  .__nav {
    text-align: center !important;
  }
  .logo-img-pack {
    width: 70%;
  }
  .__ul {
    float: none;
  }
  .center {
    text-align: center;
  }
  .__nav-tab a {
    font-size: 18px;
  }
  .__PalletsPara h3 {
    font-size: 28px;
  }
  .__PalletsPara p {
    font-size: 16px;
  }
  /* Main Image */
  .__square span {
    font-size: 44px;
  }
  .__productBg {
    height: 40vh;
  }
  .__AboutHead {
    font-size: 55px;
    margin-top: 20px;
  }
  .__aboutPackBG {
    height: 45vh;
  }
  .__mtbot {
    margin-bottom: 120px;
    margin-top: 20px;
  }
  /* Strapping tools */
  .__cros {
    padding: 0 !important;
  }
  .navtab a {
    padding: 0 12px !important;
  }
  .navtab a {
    font-size: 18px !important;
  }
  .padding0 {
    padding: 0 !important;
  }
  /* Products */
  .__heading {
    font-size: 28px;
  }
  .__details {
    font-size: 18px;
  }
  /* Contact */
  .__loveTohear {
    font-size: 37px;
  }
  .__contactreq {
    font-size: 20px;
  }
  .__officeAdd {
    font-size: 22px;
  }
  .__address {
    font-size: 17px;
  }
  .__contactForm {
    text-align: -webkit-center;
    margin-top: 10px;
  }
  .__productNameLashing::before {
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    transform: translate(0, 0);
  }
  .__productNameBox::before {
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 1200px) and (min-width: 1025px) {
  /* HEADER */
  .__mt-top {
    padding: 0;
    max-width: 1025px;
  }
  .__pos {
    padding-top: 0px;
  }
  .__li {
    padding-top: 38px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 15px;
  }
  .__forBtn {
    padding-left: 15px;
    padding-right: 0px;
  }
  .headSus {
    padding: 0px !important;
  }
  .button {
    font-size: 14px !important;
  }
  .__nav {
    text-align: center !important;
  }
  .logo-img-pack {
    width: 80%;
  }
  .__ul {
    float: none;
  }
  .center {
    text-align: center;
  }
  .__nav-tab a {
    font-size: 18px;
  }
  .__PalletsPara h3 {
    font-size: 28px;
  }
  .__PalletsPara p {
    font-size: 16px;
  }
  /* Main Image */
  .__square span {
    font-size: 44px;
  }
  .__productBg {
    height: 40vh;
  }
  .__AboutHead {
    font-size: 55px;
    margin-top: 20px;
  }
  .__aboutPackBG {
    height: 45vh;
  }
  .__mtbot {
    margin-bottom: 120px;
    margin-top: 20px;
  }
  /* Strapping tools */
  .__cros {
    padding: 0 !important;
  }
  .navtab a {
    padding: 0 12px !important;
  }
  .navtab a {
    font-size: 18px !important;
  }
  .padding0 {
    padding: 0 !important;
  }
  /* Products */
  .__heading {
    font-size: 28px;
  }
  .__details {
    font-size: 18px;
  }
  /* Contact */
  .__loveTohear {
    font-size: 37px;
  }
  .__contactreq {
    font-size: 20px;
  }
  .__officeAdd {
    font-size: 22px;
  }
  .__address {
    font-size: 17px;
  }
  .__contactForm {
    text-align: -webkit-center;
    margin-top: 10px;
  }
  .__productNameLashing::before {
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    transform: translate(0, 0);
  }
  .__productNameBox::before {
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    transform: translate(0, 0);
  }
}
/* Tab view Ipad Pro */
@media screen and (max-width: 1024px) and (min-width: 992px) {
  .__mt-top {
    display: none !important;
  }
  .__positionMobile {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding-top: 0px;
  }
  .__mobileHeader {
    display: block;
    background-color: white;
    max-width: 100%;
    position: relative;
  }
  .insideBurgerMenu{
    float: right;
    position: absolute;
    right: -25px;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .insideBurgerMenu span{
    margin-right: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #3950a1;
  }
  .insideBurgerMenu img{
    width: 40px;
  }
  .__pack-hero-bg {
    height: 50vh;
  }
  .__packSolution {
    display: none;
  }
  .__nav-tab a {
    font-size: 17px;
  }

  .__sfTableAfter::after {
    height: 290px;
  }

  .__wireBuckleTable td {
    font-size: 16px;
  }

  /* MOBILE NAVBAR */

  .mobNav {
    padding: 0;
    display: none;
  }
  .showmobnav {
    display: block;
  }

  .mobNav ul {
    padding: 0;
  }

  .mobNav ul {
    list-style: none;
    text-align: center;
    /* padding: 20px; */
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0;
  }

  .mobNav ul a:last-child {
    border-bottom: none;
  }

  .mobNav a {
    font-size: 25px;
    color: #b8b8b8;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    display: block;
    padding: 20px;
    border-bottom: 1px solid #b8b8b8;
  }

  .poMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .poMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showpomob ul a {
    display: block;
  }

  .busMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .busMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showpomob a:hover {
    text-decoration: none !important;
  }
  .showpomob a:focus {
    text-decoration: none !important;
  }

  .showbusmob a:hover {
    text-decoration: none !important;
  }
  .showbusmob a:focus {
    text-decoration: none !important;
  }

  .showbusmob ul a {
    display: block;
  }

  .top25 {
    top: 25px;
  }
  .__pdtop40 {
    padding-bottom: 20px;
  }

  /* ABOUT PAGE */
  .__serviceText {
    text-align: center;
    font-size: 40px;
  }
  .__aboutMobile {
    display: block;
  }
  .__aboutPackBG {
    height: 45vh;
  }

  .__mainContainer {
    display: none;
  }

  .__aboutImgMobile {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }

  .aboutparamobile {
    margin-top: 20px;
    padding: 0px;
  }
  .aboutparamobile p {
    color: #42240c;
    font-size: 30px;
    text-align: justify;
  }
  /* PALLETIZATION */

  .__productBg {
    height: 35vh;
  }
  .__heading {
    font-size: 1.75rem;
  }

  .__paraArea {
    margin-top: 10px;
  }

  /* LASHING TOOLS */
  .__productNameLashing::before {
    content: "LASHING & CHOCKING";
    font-family: "Airbnb Cereal App";
    font-size: 62px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 65px;
    width: 100%;
    left: 50%;
    text-align: center;
  }
  .__stlash {
    top: 60px !important;
  }

  /* Box */
  .__productNameBox::before {
    top: 65px;
    width: 100%;
    left: 50%;
    text-align: center;
  }
  /* STRAPPING TOOLS */
  .__productName::before {
    left: 50%;
    width: 100%;
    top: 65px;
    text-align: center;
  }

  .padding0 {
    padding: 0 !important;
  }

  .navtab a {
    padding: 0 10px !important;
  }

  .steel {
    margin-top: 22px;
  }

  .mt12 {
    margin-top: 12px;
  }

  /* ContactPage */
  .__loveTohearDiv,
  .__contactreqDiv {
    text-align: center;
  }

  .__contactForm {
    margin-top: 50px;
  }
  /* PALLETS INNER */
  .__cpImgs {
    width: 96%;
  }

  .fontSizeforPallet {
    font-size: 50px !important;
  }

  /* Footer */
  .__footerSection {
    margin-right: 17px;
  }
  .test {
    display: none;
  }
  .logo-img-pack-mobile {
    width: 80%;
    padding: 15px;
  }
  /* FURNITURES */
  .mrBottom {
    margin-bottom: 40px;
    margin-top: 13% !important;
  }
  .text2020:before {
    content: "2020";
    font-family: "Proxima Nova Bl";
    font-size: 125px;
    font-weight: 900;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #3950a1;
    position: relative;
    padding-left: 94px;
  }
  .copyRyt {
    font-size: 14px;
  }
  .videosection {
    height: 36rem;
  }
  #video {
    max-width: 100%;
    min-height: 0%;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .__heading {
    text-shadow: none;
  }
  .mobNav {
    padding: 0;
    display: none;
    transition: 5s all ease-in-out;
  }
  .showmobnav {
    display: block;
    transition: 5s all ease-in-out;
  }

  .mobNav ul {
    padding: 0;
  }

  .mobNav ul {
    list-style: none;
    text-align: center;
    /* padding: 20px; */
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0;
  }

  .mobNav ul a:last-child {
    border-bottom: none;
  }

  .mobNav a {
    font-size: 25px;
    color: #b8b8b8;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    display: block;
    padding: 20px;
    border-bottom: 1px solid #b8b8b8;
  }

  .poMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .poMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showpomob ul a {
    display: block;
  }
  .showpomob a:hover {
    text-decoration: none !important;
  }
  .showpomob a:focus {
    text-decoration: none !important;
  }

  .showbusmob a:hover {
    text-decoration: none !important;
  }
  .showbusmob a:focus {
    text-decoration: none !important;
  }

  .busMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .busMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showbusmob ul a {
    display: block;
  }

  .__positionMobile {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding-top: 0px;
  }
  .insideBurgerMenu{
    float: right;
    position: absolute;
    right: -25px;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .insideBurgerMenu span{
    margin-right: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #3950a1;
  }
  .insideBurgerMenu img{
    width: 40px;
  }

  .cus-col-md-3 {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
  .cus-col-md-9 {
    flex: 0 0 70% !important;
    max-width: 70% !important;
  }
  .logo-img-pack-mobile {
    width: 100%;
    padding: 8px;
  }
  .__pack-about-bg {
    display: none;
  }
  .__pack-hero-bg {
    height: 35rem;
  }

  .disableInMobile {
    display: none;
  }
  .disableInDesktop {
    display: block;
  }

  .__packSolution {
    display: none;
  }

  .__mt-top {
    display: none !important;
  }
  .test {
    display: none;
  }

  .__mobileHeader {
    display: block;
    background-color: white;
    max-width: 100%;
    position: relative;
  }

  .__accordionForMobile {
    margin-top: 70px;
  }

  .__ProductsHead {
    padding-bottom: 10%;
  }
  .arrowDown {
    margin-top: 17px;
    float: right;
  }

  .cusFluidaccordian {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .cusCard {
    border: none !important;
    margin-bottom: 15px;
    background-color: #f1f1f1;
  }
  .cusCardHead {
    border-bottom: none !important;
    background-color: #f1f1f1;
  }

  .cusBtnAccordion {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #b3b3b3;
  }

  ._accordionImg {
    text-align: center;
  }

  ._accordionImg img {
    width: 95%;
  }

  .accordionPara {
    text-align: justify;
    padding: 0 18px;
  }

  .accordionPara p {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    font-size: 25px;
    margin-top: 40px;
    text-shadow: 0px 0px 0px;
  }
  .accorbuttonProduct {
    background-color: #192965;
    border: none;
    color: white;
    padding: 15px 42%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
    margin-left: 15px;
  }

  .__tabdisableMobile {
    display: none;
  }
  .__tabdisabledesktop {
    display: block;
    margin-top: 80px;
  }
  .contactFordesktop {
    display: none;
  }

  .__cpImgs {
    width: initial;
  }

  .cus-col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .cus-col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .fontSizeforPallet {
    font-size: 50px !important;
  }

  .__serviceText {
    text-align: center;
  }
  .__aboutPackBG {
    height: 45vh;
  }
  .__aboutMobile {
    display: block;
  }
  .__aboutPackBG {
    height: 45vh;
  }

  .__mainContainer {
    display: none;
  }

  .__aboutImgMobile {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }

  .aboutparamobile {
    margin-top: 20px;
    padding: 0px;
  }
  .aboutparamobile p {
    color: #42240c;
    font-size: 25px;
    text-align: justify;
  }

  /* BOX AND CRATE */
  .__productPos {
    display: none;
    position: relative;
  }
  .__productNameBox::before {
    content: "BOX & CRATES";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 10%;
  }

  /* LASHING */

  .accordionPara p {
    color: black !important;
  }

  .__productNameLashing::before {
    content: "LASHING & CHOCKING";
    font-family: "Airbnb Cereal App";
    font-size: 45px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    width: 100%;
    text-align: center;
    padding-bottom: 10%;
  }

  /* PALLETIZATION */

  .__productNamePalletization::before {
    content: "PALLETIZATION";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 10%;
  }

  /* PALLETS */
  .__st {
    width: 80%;
  }

  .centerAlign {
    text-align: center !important;
  }

  .textAccordion {
    color: black;
    font-size: 25px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
    margin-top: 20px;
  }

  .__productNamePallet::before {
    content: "PALLET";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 10%;
  }
  .__cpTable {
    width: 100%;
  }
  .backButton {
    text-align: right;
  }
  .__wirebuckleproductdetail {
    color: black;
  }
  .__arrowimg {
    width: 47% !important;
  }

  /* STRAPPING TOOLS */

  .__productName::before {
    content: "STRAPPING";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    top: initial;
    text-align: center;
    padding-bottom: 10%;
  }

  .tabaccor td {
    color: black !important;
    font-size: 22px;
  }

  .tabaccor {
    color: black !important;
    font-size: 22px;
  }

  /* Footer */
  .__footerSection {
    display: none;
  }

  .__footerformobile {
    display: block;
  }
  .__center {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 0;
  }
  .__mobileDiv {
    text-align: center;
  }
  .__MediumImg {
    width: 40px;
    margin: 20px;
  }

  .__socioMobile {
    max-width: 150px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  /* FURNITURES */
  .mrBottom {
    margin-bottom: 40px;
    margin-top: 30px !important;
    display: none;
  }
  .text2020:before {
    content: "2020";
    font-family: "Proxima Nova Bl";
    font-size: 90px;
    font-weight: 900;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #3950a1;
    position: relative;
  }
  .furText {
    font-size: 50px;
  }
  .colText {
    font-size: 66px;
  }
  .furniture {
    top: 45px;
  }
  .attributes h3 {
    font-size: 24px;
  }
  .copyRyt {
    font-size: 16px;
  }
  .disableInMobile {
    display: none;
  }
  .disableInDeskTop {
    display: block;
  }
  .iconsFooter {
    margin-top: 18px;
  }
  .iconsFooter span img {
    width: 14%;
  }
  .text2020 {
    text-align: end;
  }
  .videosection {
    height: 33rem;
  }
  #video {
    max-width: 100%;
    min-height: 0;
    height: 40rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 420px) {
  .displayinmobile{
    display: block;
  }
  .mrbottomextended {
    margin-bottom: revert;
}
  .select-text {
    font-size: 40px;
    line-height: 1;
    text-align: center;
}
  .__heading {
    text-shadow: none;
  }
  .mobNav {
    padding: 0;
    display: none;
  }
  .showmobnav {
    display: block;
  }

  .mobNav ul {
    padding: 0;
  }

  .mobNav ul {
    list-style: none;
    text-align: center;
    /* padding: 20px; */
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0;
  }

  .mobNav ul a:last-child {
    border-bottom: none;
  }

  .mobNav a {
    font-size: 20px;
    color: #b8b8b8;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    display: block;
    padding: 16px;
    border-bottom: 1px solid #b8b8b8;
  }

  .mobNav a:hover {
    text-decoration: none;
  }

  .poMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .poMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showpomob ul a {
    display: block;
  }

  .busMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .busMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showbusmob ul a {
    display: block;
  }

  .__positionMobile {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding-top: 0px;
  }
  .insideBurgerMenu{
    float: right;
    position: absolute;
    right: -25px;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .insideBurgerMenu span{
    margin-right: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #3950a1;
  }
  .insideBurgerMenu img{
    width: 30px;
  }
  /* .menu {
    width: 13%;
    position: absolute;
    right: 20px;
    top: 25%;
  } */

  .cus-col-md-3 {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .cus-col-md-9 {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .logo-img-pack-mobile {
    width: 100%;
    padding: 8px;
  }
  .__pack-about-bg {
    display: none;
  }
  .__pack-hero-bg {
    height: 27rem;
  }

  .disableInMobile {
    display: none;
  }
  .disableInDesktop {
    display: block;
  }

  .__packSolution {
    display: none;
  }

  .__mt-top {
    display: none !important;
  }
  .test {
    display: none;
  }

  .__mobileHeader {
    display: block;
    background-color: white;
    max-width: 100%;
    position: relative;
  }
  /* HOME PAGE*/
  .__ProductsHead::before {
    font-size: 50px;
  }

  .__accordionForMobile {
    margin-top: 70px;
  }

  .__ProductsHead {
    padding-bottom: 10%;
  }
  .arrowDown {
    margin-top: 17px;
    float: right;
  }
  .cusCard {
    border: none !important;
    margin-bottom: 15px;
    background-color: #f1f1f1;
  }
  .cusCardHead {
    border-bottom: none !important;
    background-color: #f1f1f1;
  }

  .cusBtnAccordion {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #b3b3b3;
  }

  .cusBtnAccordion:hover {
    color: #b3b3b3;
  }
  .cusBtnAccordion:focus {
    text-decoration: none;
  }
  ._accordionImg {
    text-align: center;
  }

  ._accordionImg img {
    width: 95%;
  }

  .accordionPara {
    text-align: justify;
    padding: 0 0px;
  }

  .accordionPara p {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    font-size: 18px;
    margin-top: 40px;
    color: black;
    text-shadow: 0px 0px 0px;
  }
  .accorbuttonProduct {
    background-color: #192965;
    border: none;
    color: white;
    padding: 15px 30%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
  }

  .cuscardbody {
    text-align: center;
    padding: 10px;
  }
  .__GalleryPackHead::before {
    font-size: 50px;
    left: 24px;
  }

  .__Inside {
    margin-top: 9px;
    font-size: 16px;
    padding-left: 0px;
  }

  .__textEnd {
    padding-right: 13px;
    position: relative;
    bottom: 25px;
  }

  .__packFooter::before {
    font-size: 50px;
  }

  .__tabdisableMobile {
    display: none;
  }
  .__tabdisabledesktop {
    display: block;
    margin-top: 80px;
  }
  .contactFordesktop {
    display: none;
  }

  .__cpImgs {
    width: initial;
  }

  .cus-col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .cus-col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .fontSizeforPallet {
    font-size: 45px !important;
  }
  /* ABOUT PAGE */
  .__serviceText {
    font-size: 22px;
    text-align: center;
  }
  .__aboutPackBG {
    height: 20rem;
  }
  .__AboutHead {
    font-size: 60px;
  }
  .__aboutMobile {
    display: block;
  }

  .__mainContainer {
    display: none;
  }

  .__aboutImgMobile {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }

  .aboutparamobile {
    margin-top: 20px;
    padding: 0px;
  }
  .aboutparamobile p {
    color: #42240c;
    font-size: 20px;
    text-align: justify;
  }

  /* GALLERY */
  .__column {
    flex: 100% 1;
    max-width: 100%;
    padding: 0 6px;
  }

  .__cusRowInGallery {
    padding-left: 7px;
    padding-right: 7px;
  }

  .__cusContainerFromGallery {
    margin-top: 40px;
  }

  /* CONTACT PAGE */
  .__contactFormSection {
    margin-top: 50px;
  }
  .__loveTohear {
    font-size: 40px;
    text-align: center;
  }
  .__contactreq {
    font-size: 20px;
    text-align: center;
  }
  .cusCol4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .cusCol8 {
    flex: 0 0 66.333333%;
    max-width: 66.333333%;
  }
  .__address {
    font-size: 15px;
  }
  .__companyImg {
    width: 100%;
  }
  .__officeAdd {
    font-size: 22px;
  }
  .__locateUs {
    padding: 1rem 7.6rem;
    margin-top: 0px;
  }
  .__contactForm {
    margin-top: 50px;
  }
  .__SubmitButton {
    padding: 1rem 7.6rem;
    margin-top: 0px;
  }
  .__Connectus {
    display: none;
  }
  /* BOX AND CRATE */
  .__productPos {
    display: none;
    position: relative;
  }
  .__productNameBox::before {
    content: "BOX & CRATES";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 15%;
    width: 100%;
    text-align: center;
  }
  /* PALLETIZATION */

  .__productNamePalletization::before {
    content: "PALLETIZATION";
    font-family: "Airbnb Cereal App";
    font-size: 46px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 13%;
    width: 100%;
    text-align: center;
  }
  /* LASHING */

  .accordionPara p {
    color: black !important;
  }

  .__productNameLashing::before {
    content: "LASHING & CHOCKING";
    font-family: "Airbnb Cereal App";
    font-size: 34px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    width: 100%;
    text-align: center;
    padding-bottom: 10%;
  }
  /* STRAPPING TOOLS */

  .__productName::before {
    content: "STRAPPING";
    font-family: "Airbnb Cereal App";
    font-size: 40px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    top: initial;
    text-align: center;
    padding-bottom: 10%;
  }
  .tabaccor {
    color: black;
    font-size: 20px;
  }
  .tabaccor td {
    color: black;
    font-size: 14px;
  }
  /* PALLETS */
  .__st {
    width: 80%;
  }

  .centerAlign {
    text-align: center !important;
  }

  .textAccordion {
    color: black;
    font-size: 25px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
    margin-top: 20px;
  }

  .__productNamePallet::before {
    content: "PALLET";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 14%;
  }
  .__cpTable {
    width: 100%;
  }
  .backButton {
    text-align: right;
    display: none;
  }
  .__wirebuckleproductdetail {
    color: black;
  }
  .forPara {
    padding: 0 !important;
  }
  .__arrowimg {
    width: 47% !important;
  }

  /* Footer */
  .__footerSection {
    display: none;
  }

  .__footerformobile {
    display: block;
  }
  .__center {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 0;
  }
  .__mobileDiv {
    text-align: center;
  }
  .__MediumImg {
    width: 40px;
    margin: 20px;
  }

  .__socioMobile {
    max-width: 150px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .cusCol4footer {
    flex: 0 0 10%;
    max-width: 10%;
    margin-right: 10px;
  }
  /* FURNITURES */
  .logoInFurniture {
    width: 20%;
  }
  .mrBottom {
    display: none;
  }
  .text2020:before {
    content: "2020";
    font-family: "Proxima Nova Bl";
    font-size: 90px;
    font-weight: 900;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #3950a1;
    position: relative;
  }
  .furText {
    font-size: 50px;
  }
  .colText {
    font-size: 66px;
  }
  .furniture {
    top: 45px;
  }
  .attributes h3 {
    font-size: 24px;
  }
  .copyRyt {
    font-size: 16px;
    text-align: center;
    margin: 15px 0;
  }
  .disableInMobile {
    display: none;
  }
  .disableInDeskTop {
    display: block;
  }
  .iconsFooter {
    margin-top: 0px;
  }
  .iconsFooter span img {
    width: 7%;
  }
  .text2020 {
    text-align: end;
  }
  .md6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  /* .mrTopDiv {
    margin-top: 40px;
  } */
  .md3,
  .mdfooter6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .lineDiv1::after,
  .lineDiv2::after {
    display: none;
  }
  .colMargin {
    margin-top: 40px !important;
  }
  .maxWidthForProductMobile {
    max-width: 885px;
    margin: 0 auto;
    display: block;
  }
  .maxWidthForProduct {
    display: none;
  }
  .productDescription h2 {
    font-size: 22px;
  }
  .videosection {
    height: 20rem;
  }
  #video {
    max-width: 100%;
    min-height: 0;
    height: 25rem;
  }
  .md6 h3{
    font-size: 20px;
    font-family: "Proxima Nova Rg";
    font-weight: normal;
  }
  .md6 hr{
    display: block;
  }
  .md6 br{
    display: none;
  }
  .collection {  
   margin-top: 0px;     
  }
}

@media screen and (max-width: 419px) and (min-width: 360px) {
  
  .displayinmobile{
    display: block;
  }
  .mrbottomextended {
    margin-bottom: revert;
}
  .select-text {
    font-size: 35px;
    line-height: 1;
    text-align: center;
}
  .__heading {
    text-shadow: none;
  }
  .mobNav {
    padding: 0;
    display: none;
  }
  .showmobnav {
    display: block;
  }

  .mobNav ul {
    padding: 0;
  }

  .mobNav ul {
    list-style: none;
    text-align: center;
    /* padding: 20px; */
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0;
  }

  .mobNav ul a:last-child {
    border-bottom: none;
  }

  .mobNav a {
    font-size: 19px;
    color: #b8b8b8;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    display: block;
    padding: 15px;
    border-bottom: 1px solid #b8b8b8;
  }
  .mobNav a:hover {
    text-decoration: none;
  }

  .poMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .poMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 16px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showpomob ul a {
    display: block;
  }

  .busMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .busMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 16px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showbusmob ul a {
    display: block;
  }

  .__positionMobile {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding-top: 0px;
  }
  .insideBurgerMenu{
    float: right;
    position: absolute;
    right: -25px;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .insideBurgerMenu span{
    margin-right: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #3950a1;
  }
  .insideBurgerMenu img{
    width: 30px;
  }
  .cus-col-md-3 {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .cus-col-md-9 {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .logo-img-pack-mobile {
    width: 100%;
    padding: 8px;
  }
  .__pack-about-bg {
    display: none;
  }
  .__pack-hero-bg {
    height: 18rem;
  }

  .disableInMobile {
    display: none;
  }
  .disableInDesktop {
    display: block;
  }

  .__packSolution {
    display: none;
  }

  .__mt-top {
    display: none !important;
  }
  .test {
    display: none;
  }

  .__mobileHeader {
    display: block;
    background-color: white;
    max-width: 100%;
    position: relative;
  }
  /* HOME PAGE*/
  .__ProductsHead::before {
    font-size: 50px;
  }

  .__accordionForMobile {
    margin-top: 70px;
  }

  .__ProductsHead {
    padding-bottom: 10%;
  }
  .arrowDown {
    margin-top: 17px;
    float: right;
  }
  .cusCard {
    border: none !important;
    margin-bottom: 15px;
    background-color: #f1f1f1;
  }
  .cusCardHead {
    border-bottom: none !important;
    background-color: #f1f1f1;
  }

  .cusBtnAccordion {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #b3b3b3;
  }
  .cusBtnAccordion:hover {
    color: #b3b3b3;
  }
  .cusBtnAccordion:focus {
    text-decoration: none;
  }

  ._accordionImg {
    text-align: center;
  }

  ._accordionImg img {
    width: 95%;
  }

  .accordionPara {
    text-align: justify;
    padding: 0 0px;
  }

  .accordionPara p {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    font-size: 18px;
    margin-top: 40px;
    color: black;
    text-shadow: 0px 0px 0px;
  }
  .accorbuttonProduct {
    background-color: #192965;
    border: none;
    color: white;
    padding: 15px 30%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
  }

  .cuscardbody {
    text-align: center;
    padding: 10px;
  }
  .__GalleryPackHead::before {
    font-size: 50px;
    left: 24px;
  }

  .__Inside {
    margin-top: 9px;
    font-size: 14px;
    padding-left: 0px;
  }

  .__textEnd {
    padding-right: 13px;
    position: relative;
    bottom: 25px;
  }
  .__Connectus {
    display: none;
  }

  .__fluidCon {
    padding-left: 15px;
    padding-right: 15px;
  }

  .__packFooter::before {
    font-size: 50px;
  }

  .__tabdisableMobile {
    display: none;
  }
  .__tabdisabledesktop {
    display: block;
    margin-top: 80px;
  }
  .contactFordesktop {
    display: none;
  }

  .__cpImgs {
    width: 100%;
  }

  .__cpPara {
    font-size: 20px;
  }
  .__cpTable tr {
    font-size: 17px;
  }

  .__cpPara ul {
    padding-left: 20px;
  }

  .__productNameTable {
    padding-right: 28px;
  }

  .cus-col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .cus-col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .fontSizeforPallet {
    font-size: 35px !important;
  }
  /* ABOUT PAGE */
  .__serviceText {
    font-size: 22px;
    text-align: center;
  }
  .__aboutPackBG {
    height: 20rem;
  }
  .__AboutHead {
    font-size: 50px;
  }
  .__aboutMobile {
    display: block;
  }

  .__mainContainer {
    display: none;
  }

  .__aboutImgMobile {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }

  .aboutparamobile {
    margin-top: 20px;
    padding: 0px;
  }
  .aboutparamobile p {
    color: #42240c;
    font-size: 17px;
    text-align: justify;
  }

  /* GALLERY */
  .__column {
    flex: 100% 1;
    max-width: 100%;
    padding: 0 6px;
  }

  .__cusRowInGallery {
    padding-left: 7px;
    padding-right: 7px;
  }

  .__cusContainerFromGallery {
    margin-top: 40px;
  }
  /* CONTACT PAGE */
  .__contactFormSection {
    margin-top: 50px;
  }
  .__loveTohear {
    font-size: 40px;
    text-align: center;
  }
  .__contactreq {
    font-size: 20px;
    text-align: center;
  }
  .cusCol4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .cusCol8 {
    flex: 0 0 66.333333%;
    max-width: 66.333333%;
  }
  .__address {
    font-size: 12px;
  }
  .__companyImg {
    width: 100%;
  }
  .__officeAdd {
    font-size: 19px;
  }
  .__locateUs {
    padding: 1rem 6.6rem;
    margin-top: 0px;
    font-size: 12px;
  }
  .__contactForm {
    margin-top: 50px;
  }
  .__SubmitButton {
    padding: 1rem 6.6rem;
    margin-top: 0px;
    font-size: 12px;
  }
  .__Connectus {
    display: none;
  }
  /* BOX AND CRATE */
  .__productPos {
    display: none;
    position: relative;
  }
  .__productNameBox::before {
    content: "BOX & CRATES";
    font-family: "Airbnb Cereal App";
    font-size: 40px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 15%;
    width: 100%;
    text-align: center;
  }
  /* PALLETIZATION */

  .__productNamePalletization::before {
    content: "PALLETIZATION";
    font-family: "Airbnb Cereal App";
    font-size: 36px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 13%;
    width: 100%;
    text-align: center;
  }
  /* LASHING */

  .accordionPara p {
    color: black !important;
  }

  .__productNameLashing::before {
    content: "LASHING & CHOCKING";
    font-family: "Airbnb Cereal App";
    font-size: 28px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    width: 100%;
    text-align: center;
    padding-bottom: 10%;
  }
  /* STRAPPING TOOLS */

  .__productName::before {
    content: "STRAPPING";
    font-family: "Airbnb Cereal App";
    font-size: 34px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    top: initial;
    text-align: center;
    padding-bottom: 10%;
  }
  .tabaccor {
    color: black;
    font-size: 20px;
  }
  .tabaccor td {
    color: black;
    font-size: 14px;
  }
  /* PALLETS */
  .__st {
    width: 80%;
  }

  .centerAlign {
    text-align: center !important;
  }

  .textAccordion {
    color: black;
    font-size: 25px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
    margin-top: 20px;
  }

  .__productNamePallet::before {
    content: "PALLET";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 14%;
  }
  .__cpTable {
    width: 100%;
  }
  .backButton {
    text-align: right;
    display: none;
  }
  .dis {
    padding-left: 5px;
  }
  .forPara {
    padding: 0 !important;
  }
  .__arrowimg {
    width: 47% !important;
  }
  /* Footer */
  .__footerSection {
    display: none;
  }

  .__footerformobile {
    display: block;
  }
  .__center {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 0;
  }
  .__mobileDiv {
    text-align: center;
  }
  .__MediumImg {
    width: 40px;
    margin: 20px;
  }

  .__socioMobile {
    max-width: 150px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .cusCol4footer {
    flex: 0 0 10%;
    max-width: 10%;
    margin-right: 10px;
  }
  /* FURNITURES */
  .logoInFurniture {
    width: 20%;
  }

  .mrBottom {
    display: none;
  }
  /* .marTopsec2 {
    margin: 0px 0;
  } */
  .text2020:before {
    content: "2020";
    font-family: "Proxima Nova Bl";
    font-size: 90px;
    font-weight: 900;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #3950a1;
    position: relative;
  }
  .furText {
    font-size: 50px;
  }
  .colText {
    font-size: 66px;
  }
  .furniture {
    top: 45px;
  }
  .attributes h3 {
    font-size: 20px;
  }
  .collection h1 {
    font-size: 27px;
  }
  .copyRyt {
    font-size: 16px;
    text-align: center;
    margin: 15px 0;
  }
  .disableInMobile {
    display: none;
  }
  .disableInDeskTop {
    display: block;
  }
  .iconsFooter {
    margin-top: 0px;
  }
  .iconsFooter span img {
    width: 7%;
  }
  .text2020 {
    text-align: end;
  }
  .md6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  /* .mrTopDiv {
    margin-top: 40px;
  } */
  .md3,
  .mdfooter6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .lineDiv1::after,
  .lineDiv2::after {
    display: none;
  }
  .colMargin {
    margin-top: 30px !important;
  }
  .maxWidthForProductMobile {
    max-width: 885px;
    margin: 0 auto;
    display: block;
  }
  .maxWidthForProduct {
    display: none;
  }
  .productDescription h2 {
    font-size: 20px;
  }
  .videosection {
    height: 16rem;
  }
  #video {
    max-width: 100%;
    min-height: 0;
    height: 20rem;
  }
  .md6 h3{
    font-size: 20px;
    font-family: "Proxima Nova Rg";
    font-weight: normal;
  }
  .md6 hr{
    display: block;
  }
  .md6 br{
    display: none;
  }
  .collection {  
   margin-top: 0px;     
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .displayinmobile{
    display: block;
  }
  .mrbottomextended {
    margin-bottom: revert;
}
  .select-text {
    font-size: 30px;
    line-height: 1;

    text-align: center;
}
  .displayinmobile{
    display: block;
  }
  .__heading {
    text-shadow: none;
  }
  .mobNav {
    padding: 0;
    display: none;
  }
  .showmobnav {
    display: block;
  }

  .mobNav ul {
    padding: 0;
  }

  .mobNav ul {
    list-style: none;
    text-align: center;
    /* padding: 20px; */
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0;
  }

  .mobNav ul a:last-child {
    border-bottom: none;
  }

  .mobNav a {
    font-size: 17px;
    color: #b8b8b8;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    display: block;
    padding: 14px;
    border-bottom: 1px solid #b8b8b8;
  }
  .mobNav a:hover {
    text-decoration: none;
  }

  .poMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .poMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showpomob ul a {
    display: block;
  }

  .busMob ul {
    padding: 0;
    background-color: #b8b8b85c;
  }

  .busMob ul a {
    display: none;
    padding: 12px 20px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    font-weight: bold;
    color: black;
  }

  .showbusmob ul a {
    display: block;
  }

  .__positionMobile {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    padding-top: 0px;
  }
  .insideBurgerMenu{
    float: right;
    position: absolute;
    right: -25px;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .insideBurgerMenu span{
    margin-right: 15px;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #3950a1;
  }
  .insideBurgerMenu img{
    width: 30px;
  }

  .cus-col-md-3 {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .cus-col-md-9 {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .logo-img-pack-mobile {
    width: 100%;
    padding: 8px;
  }
  .__pack-about-bg {
    display: none;
  }
  .__pack-hero-bg {
    height: 18rem;
  }

  .disableInMobile {
    display: none;
  }
  .disableInDesktop {
    display: block;
  }

  .__packSolution {
    display: none;
  }

  .__mt-top {
    display: none !important;
  }
  .test {
    display: none;
  }

  .__mobileHeader {
    display: block;
    background-color: white;
    max-width: 100%;
    position: relative;
  }
  /* HOME PAGE*/
  .__ProductsHead::before {
    font-size: 36px;
  }

  .__accordionForMobile {
    margin-top: 70px;
  }

  .__ProductsHead {
    padding-bottom: 10%;
  }
  .arrowDown {
    margin-top: 17px;
    float: right;
  }
  .cusCard {
    border: none !important;
    margin-bottom: 15px;
    background-color: #f1f1f1;
  }
  .cusCardHead {
    border-bottom: none !important;
    background-color: #f1f1f1;
  }

  .cusBtnAccordion {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    color: #b3b3b3;
    font-size: 13px;
  }

  .cusBtnAccordion:hover {
    color: #b3b3b3;
  }
  .cusBtnAccordion:focus {
    text-decoration: none;
  }

  ._accordionImg {
    text-align: center;
  }

  ._accordionImg img {
    width: 95%;
  }

  .accordionPara {
    text-align: justify;
    padding: 0 0px;
  }

  .accordionPara p {
    font-family: "Proxima Nova Lt";
    font-weight: 600;
    font-size: 16px;
    margin-top: 40px;
    color: black;
    text-shadow: 0px 0px 0px;
  }
  .accorbuttonProduct {
    background-color: #192965;
    border: none;
    color: white;
    padding: 15px 30%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
  }

  .cuscardbody {
    text-align: center;
    padding: 10px;
  }
  .__GalleryPackHead::before {
    font-size: 36px;
    left: 24px;
    top: -20px;
  }

  .__Inside {
    margin-top: 9px;
    font-size: 10px;
    padding-left: 0px;
  }

  .__textEnd {
    padding-right: 13px;
    position: relative;
    bottom: 25px;
  }
  .__insideJCP {
    padding-bottom: 70px;
  }

  .__Connectus {
    display: none;
  }

  .__fluidCon {
    padding-left: 15px;
    padding-right: 15px;
  }

  .__packFooter::before {
    font-size: 36px;
  }

  .__tabdisableMobile {
    display: none;
  }
  .__tabdisabledesktop {
    display: block;
    margin-top: 80px;
  }
  .contactFordesktop {
    display: none;
  }

  .__cpImgs {
    width: 100%;
  }

  .__cpPara {
    font-size: 18px;
  }
  .__cpTable tr {
    font-size: 15px;
  }

  .__cpPara ul {
    padding-left: 20px;
  }

  .__productNameTable {
    padding-right: 20px;
  }

  .cus-col-5 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .cus-col-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .fontSizeforPallet {
    font-size: 30px !important;
  }
  /* GALLERY */
  .__column {
    flex: 100% 1;
    max-width: 100%;
    padding: 0 6px;
  }

  .__cusRowInGallery {
    padding-left: 7px;
    padding-right: 7px;
  }

  .__cusContainerFromGallery {
    margin-top: 40px;
  }
  /* ABOUT PAGE */
  .__serviceText {
    font-size: 22px;
    text-align: center;
  }
  .__aboutPackBG {
    height: 15rem;
  }
  .__AboutHead {
    font-size: 45px;
  }
  .__aboutMobile {
    display: block;
  }

  .__mainContainer {
    display: none;
  }

  .__aboutImgMobile {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }

  .aboutparamobile {
    margin-top: 20px;
    padding: 0px;
  }
  .aboutparamobile p {
    color: #42240c;
    font-size: 18px;
    text-align: justify;
  }
  /* CONTACT PAGE */
  .__contactFormSection {
    margin-top: 50px;
  }
  .__loveTohear {
    font-size: 40px;
    text-align: center;
  }
  .__contactreq {
    font-size: 20px;
    text-align: center;
  }
  .cusCol4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .cusCol8 {
    flex: 0 0 66.333333%;
    max-width: 66.333333%;
  }
  .__address {
    font-size: 10px;
  }
  .__companyImg {
    width: 100%;
  }
  .__officeAdd {
    font-size: 16px;
  }
  .__locateUs {
    padding: 1rem 3.6rem;
    margin-top: 0px;
    font-size: 12px;
  }
  .__contactForm {
    margin-top: 50px;
  }
  .__SubmitButton {
    padding: 1rem 3.6rem;
    margin-top: 0px;
    font-size: 12px;
  }
  .__Connectus {
    display: none;
  }
  /* BOX AND CRATE */
  .__productPos {
    display: none;
    position: relative;
  }
  .__productNameBox::before {
    content: "BOX & CRATES";
    font-family: "Airbnb Cereal App";
    font-size: 30px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 15%;
    width: 100%;
    text-align: center;
  }
  /* PALLETIZATION */

  .__productNamePalletization::before {
    content: "PALLETIZATION";
    font-family: "Airbnb Cereal App";
    font-size: 36px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 13%;
    width: 100%;
    text-align: center;
  }
  /* LASHING */

  .accordionPara p {
    color: black !important;
  }

  .__productNameLashing::before {
    content: "LASHING & CHOCKING";
    font-family: "Airbnb Cereal App";
    font-size: 25px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    width: 100%;
    text-align: center;
    padding-bottom: 10%;
  }
  /* STRAPPING TOOLS */

  .__productName::before {
    content: "STRAPPING";
    font-family: "Airbnb Cereal App";
    font-size: 30px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    top: initial;
    text-align: center;
    padding-bottom: 10%;
  }
  .tabaccor {
    color: black;
    font-size: 20px;
  }
  .tabaccor td {
    color: black;
    font-size: 14px;
  }
  /* PALLETS */
  .__st {
    width: 80%;
  }

  .centerAlign {
    text-align: center !important;
  }

  .textAccordion {
    color: black;
    font-size: 25px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
    margin-top: 20px;
  }

  .__productNamePallet::before {
    content: "PALLET";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 14%;
  }
  .__cpTable {
    width: 100%;
  }
  .backButton {
    text-align: right;
    display: none;
  }
  .dis {
    padding-left: 5px;
  }
  .forPara {
    padding: 0 !important;
  }
  /* PALLETS */
  .__st {
    width: 80%;
  }

  .centerAlign {
    text-align: center !important;
  }

  .textAccordion {
    color: black;
    font-size: 25px;
    font-family: "Proxima Nova Rg";
    font-weight: bold;
    margin-top: 20px;
  }

  .__productNamePallet::before {
    content: "PALLET";
    font-family: "Airbnb Cereal App";
    font-size: 50px;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #fd5e53;
    text-shadow: -3px 3px 8px #b10b0080;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: initial;
    padding-bottom: 14%;
  }
  .__arrowimg {
    width: 47% !important;
  }

  /* Footer */
  .__footerSection {
    display: none;
  }

  .__footerformobile {
    display: block;
  }
  .__center {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 0;
    padding-top: 7px;
  }
  .__mobileDiv {
    text-align: center;
  }
  .__MediumImg {
    width: 40px;
    margin: 20px;
  }

  .__socioMobile {
    max-width: 150px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .cusCol4footer {
    flex: 0 0 10%;
    max-width: 10%;
    margin-right: 10px;
  }
  /* FURNITURES */
  .logoInFurniture {
    width: 20%;
  }
  .mrBottom {
    display: none;
  }
  .text2020:before {
    content: "2020";
    font-family: "Proxima Nova Bl";
    font-size: 90px;
    font-weight: 900;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    z-index: 99;
    -webkit-text-stroke-color: #3950a1;
    position: relative;
  }
  .furText {
    font-size: 50px;
  }
  .colText {
    font-size: 66px;
  }
  .furniture {
    top: 45px;
  }
  .attributes h3 {
    font-size: 18px;
  }
  .squareBG {
    height: 37px;
  }
  .collection h1 {
    font-size: 27px;
  }
  .copyRyt {
    font-size: 16px;
    text-align: center;
    margin: 15px 0;
  }
  .disableInMobile {
    display: none;
  }
  .disableInDeskTop {
    display: block;
  }
  .iconsFooter {
    margin-top: 0px;
  }
  .iconsFooter span img {
    width: 7%;
  }
  .text2020 {
    text-align: end;
  }
  .md6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  /* .mrTopDiv {
    margin-top: 40px;
  } */
  .md3,
  .mdfooter6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .lineDiv1::after,
  .lineDiv2::after {
    display: none;
  }
  .colMargin {
    margin-top: 30px !important;
  }
  .maxWidthForProductMobile {
    max-width: 885px;
    margin: 0 auto;
    display: block;
  }
  .maxWidthForProduct {
    display: none;
  }
  .productDescription h2 {
    font-size: 16px;
  }
  .videosection {
    height: 14rem;
  }
  #video {
    max-width: 100%;
    min-height: 0;
    height: 18rem;
  }
  .md6 h3{
    font-size: 16px;
    font-family: "Proxima Nova Rg";
    font-weight: normal;
  }
  .md6 hr{
    display: block;
  }
  .md6 br{
    display: none;
  }
  .collection {  
   margin-top: 0px;     
  }
}
