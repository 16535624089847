.cus-nav {
  /* text-align: center;
  margin: auto; */
}

.cus-ul {
  padding: 0px;
  text-align: center;
  margin: 0 auto;
}

.cus-ul li {
  list-style: none;
  display: inline-block;
  padding-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 600;
  font-size: 20px;
  font-family: "Proxima Nova Lt";
}

.cus-ul li {
  text-decoration: none;
  color: black;
}

.cus-ul li a {
  text-decoration: none;
  color: black;
}

.dropdown-menu {
  font-family: "Proxima Nova Rg" !important;
  font-weight: normal !important;
}

@media screen and (max-width: 767px) and (min-width: 420px) {
  .mainHeadMob {
    padding: 0 !important;
    display: none;
  }
  .display {
    display: block;
  }
  .mainHeadMob ul {
    list-style: none;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0;
    padding: 0;
  }
  .mainHeadMob a {
    font-size: 20px;
    display: block;
    padding: 16px;
    border-bottom: 1px solid #b8b8b8;
    color: black;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
  }

  .mainHeadMob a:last-child {
    border-bottom: none;
  }
  .mainHeadMob a:hover {
    color: black;
    text-decoration: none;
  }
  .submenu ul {
    display: none;
  }
  .submenuopen ul {
    display: block;
    background-color: #e5e5e5;
  }
}

@media screen and (max-width: 419px) and (min-width: 360px) {
  .mainHeadMob {
    padding: 0 !important;
    display: none;
  }
  .display {
    display: block;
  }
  .mainHeadMob ul {
    list-style: none;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0;
    padding: 0;
  }
  .mainHeadMob a {
    font-size: 18px;
    display: block;
    padding: 16px;
    border-bottom: 1px solid #b8b8b8;
    color: black;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
  }

  .mainHeadMob a:last-child {
    border-bottom: none;
  }
  .mainHeadMob a:hover {
    color: black;
    text-decoration: none;
  }
  .submenu ul {
    display: none;
  }
  .submenuopen ul {
    display: block;
    background-color: #e5e5e5;
  }
}

@media screen and (max-width: 359px) and (min-width: 320px) {
  .mainHeadMob {
    padding: 0 !important;
    display: none;
  }
  .display {
    display: block;
  }
  .mainHeadMob ul {
    list-style: none;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0;
    padding: 0;
  }
  .mainHeadMob a {
    font-size: 18px;
    display: block;
    padding: 16px;
    border-bottom: 1px solid #b8b8b8;
    color: black;
    font-family: "Proxima Nova Lt";
    font-weight: 600;
  }

  .mainHeadMob a:last-child {
    border-bottom: none;
  }
  .mainHeadMob a:hover {
    color: black;
    text-decoration: none;
  }
  .submenu ul {
    display: none;
  }
  .submenuopen ul {
    display: block;
    background-color: #e5e5e5;
  }
}