.hero-bg {
  background-image: url("../assets/Images/Group\ 78.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.logo-div {
  text-align: right;
}

.logo-img {
  width: 62%;
  padding-top: 50px;
}

.div-align {
  margin-top: 70px;
  margin-left: -42px;
}

.rectangle {
  width: 60%;
  background-color: transparent;
  outline: 2px solid #3950a1;
  text-align: center;
}

.rectangle p {
  margin-bottom: 0rem !important;
}

.welcome {
  font-family: "Proxima Nova Rg";
  font-size: 50px;
  font-weight: bold;
}

.rectangle_2 {
  width: 100%;
  background-color: #3950a1;
  outline: 2px solid #3950a1;
  text-align: center;
}
.rectangle_2 p {
  margin-bottom: 0rem !important;
}

.name {
  font-family: "Proxima Nova Rg";
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.rectangle_3 {
  width: 82%;
  background-color: transparent;
  outline: 2px solid #3950a1;
  text-align: center;
}

.work {
  font-family: "Proxima Nova Lt";
  font-size: 37px;
  font-weight: 300;
  color: black;
}
